import colors from 'Components/colors.scss'
import { AuthContext } from 'Context'
import useCachedGetData from 'Hooks/useCachedGetData'
import useGetData from 'Hooks/useGetData'
import _ from 'lodash'
import React, { useContext, useMemo } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { transformLocations } from 'Utils/location'
import { globalSearch } from 'Utils/search'
import { Checkbox, CheckboxGroup, MultiSelect, TextInput, Tree } from '../input'
import { Expander, ExpanderTab } from '../readonly'

export const filterColors = {
  he: colors.filterColor32,
  pd: colors.filterColor22,
  prio: colors.filterColor53,
  candidate: colors.filterColor42,
  listingsList: colors.filterColor12,
  tagList: colors.filterColor52,
  remarkList: colors.filterColor63,
  location: colors.filterColor31,
  admin: colors.black
}

const HeaderThree = styled.h3`
  padding-top: 30px;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 7px;
  }
`

export const chemicalSearch = (productList, search) => {
  return globalSearch(
    productList,
    search.toLowerCase(),
    [
      'name',
      'organisation_synonym',
      'supplier',
      'status',
      'article_number',
      'organisation_article_number',
      'un_number',
      'transport_information',
      'created_by_organisation.name'
    ],
    [ingredientSearch, hazardSearch, precautionarySearch]
  )
}

const ingredientSearch = (item, search) => {
  if(!item?.chemical_ingredients) {
    return false;
  }
  return item.chemical_ingredients.some((ingredient) => {
    let v = ingredient.ingredient_variant.ingredient
    return (
      (v.cas_id && v.cas_id.toLowerCase()) === search ||
      (v.names && v.names.some((n) => n.name && n.name.toLowerCase().includes(search))) ||
      (v.user_language_name && v.user_language_name.some((n) => n.name && n.name.toLowerCase().includes(search)))
    )
  })
};

const hazardSearch = (item, search) => {
  if(!item?.hazard_statements) {
    return false;
  }

  return item.hazard_statements.some((hs) => hs.code.toLowerCase().includes(search))
}

const precautionarySearch = (item, search) => {
  if(!item?.precautionary_statements) {
    return false;
  }
  return item.precautionary_statements.some((ps) => ps.code.toLowerCase().includes(search))
}

const checkHList = (h, clp) => {
  return h?.code?.split(' + ').some((c) => clp?.indexOf(c) > -1)
}

const checkCList = (c, clp) => {
  return clp?.includes(c.id)
}

const cmrAbbrevs = ['Muta. 1A', 'Muta. 1B', 'Carc. 1A', 'Carc. 1B', 'Repr. 1A', 'Repr. 1B']

const checkListingsList = (item, listingFilters) => {
  return listingFilters.some((listingFilter) => {
    if (listingFilter.value === 'cmr') {
      return item.hazard_categories.some((category) => cmrAbbrevs.some((cmrAbbrev) => cmrAbbrev === category.abbreviation.trim()))
    } else if (listingFilter.value === 'allergens') {
      return item.hazard_statements?.some((h) => checkHList(h, listingFilter.filterList))
    } else {
      return (
        item.chemical_ingredients &&
        item.chemical_ingredients.some((chemicalIngredients) => {
          return (
            chemicalIngredients.ingredient_variant.flagged_for_ingredient_filters.includes(listingFilter.value) ||
            chemicalIngredients.ingredient_variant.ingredient.matched_ingredients_filters.includes(listingFilter.value)
          )
        })
      )
    }
  })
}

export const filterList = (list, search, filter) => {
  let filtered = chemicalSearch(list, search)
  let clpFilter = filter.he.concat(filter.pd).map((v) => v.value)
  let prioFilter = filter.prio.map((v) => v.value)
  let tagIdList = filter.tagList ? filter.tagList.map((v) => v.value) : []
  if (
    clpFilter.length > 0 ||
    prioFilter.length > 0 ||
    filter.candidate ||
    tagIdList.length > 0 ||
    filter.remarkList.length > 0 ||
    filter.listingsList.length > 0 ||
    (filter.hazardCategories && filter.hazardCategories.length > 0) ||
    (filter.hazardStatements && filter.hazardStatements.length > 0) ||
    (filter.precautionaryStatements && filter.precautionaryStatements.length > 0) ||
    (filter.supplementalHazardInformation && filter.supplementalHazardInformation.length > 0)
  ) {
    filtered = filtered.filter((p) => {
      return (
        clpFilter.some((clp) => p.hazard_statements.some((h) => checkHList(h, clp))) ||
        prioFilter.some((prio) =>
          p.chemical_ingredients.some(
            (ci) => ci.ingredient_variant.listed_prio || ci.ingredient_variant.hazard_categories.some((c) => checkCList(c, prio))
          )
        ) ||
        (filter.candidate && checkListingsList(p, [{ value: 'candidate' }])) ||
        (p.tags && p.tags.some((tag) => tagIdList.some((tagId) => tagId === +tag.value))) ||
        checkListingsList(p, filter.listingsList) ||
        filter.remarkList.some((remark) => {
          switch (remark.value) {
            case 'max_risk_value':
              return p.max_risk_value > 0.63
            case 'reason_not_trustworthy':
            case '!reason_not_trustworthy':
              const trustworthy =
                (p.reason_not_trustworthy_phrases && p.reason_not_trustworthy_phrases.length > 0) ||
                p.chemical_ingredients.some((ci) => ci.ingredient_variant.classification_incorrect)
              if (remark.value[0] === '!') {
                return !trustworthy
              } else {
                return trustworthy
              }

            default:
              return p[remark.value]
          }
        }) ||
        (filter.hazardCategories &&
          p.hazard_categories.some((hc) => filter.hazardCategories.some((filter) => hc.abbreviation === filter))) ||
        (filter.hazardStatements && p.hazard_statements.some((hs) => filter.hazardStatements.some((filter) => hs.code === filter))) ||
        (filter.precautionaryStatements &&
          p.precautionary_statements.some((ps) => filter.precautionaryStatements.some((filter) => ps.code === filter))) ||
        (filter.supplementalHazardInformation &&
          p.supplemental_hazard_information.toLowerCase().includes(filter.supplementalHazardInformation.toLowerCase()))
      )
    })
  }
  if (filter.locations && !_.isEmpty(filter.locations.selectedKeys)) {
    filtered = filtered.filter(
      (p) =>
        !_.isEmpty(filter.locations.selectedKeys) &&
        p.locations.some((l) => filter.locations.selectedKeys.some((fl) => fl + '' === l.location_id + ''))
    )
  }

  return filtered
}

const getRemartListOptionIds = options => {
  const valueToId = {
    1: "hygiene_threshold_incorrect",
    2: "sdb_reach_incompliance",
    3: "sdb_atp_incompliance"
  }
  return options.map(opt => ({name: opt.label, id: valueToId[opt.value]}))
}

const ChemicalFilter = ({ filter, setFirst, intl, lang }) => {
  const { user, organisation } = useContext(AuthContext)
  const [hazardStatementFilterLoading, hazardStatementFilter] = useCachedGetData('/rapi/admin/filter/statement')
  const [hazardCategoryFilterLoading, hazardCategoryFilter] = useCachedGetData('/rapi/admin/filter/category')
  const [locationsLoading, locations] = useGetData('/rapi/locations', null, [organisation])
  const [isTagListLoading, tagList] = useGetData('/rapi/tags', null, [organisation])
  const [isHazardCategoriesLoading, hazardCategories] = useCachedGetData('/rapi/chemicals/hazardcategories')
  const [isHazardStatementsLoading, hazardStatements] = useCachedGetData('/rapi/chemicals/hazardstatements')
  const [isPrecautionaryStatementsLoading, precautionaryStatements] = useCachedGetData('/rapi/chemicals/precautionarystatements')
  const [, reasonNotTrustworthyPhrases] = useCachedGetData('/rapi/admin/reasonnottrustworthyphrases/' + user.user.language, [])

  const hazardCategoryOptions = React.useMemo(() => {
    if (!hazardCategories) return []
    return _.uniq(hazardCategories.map((hc) => hc.abbreviation))
      .sort()
      .map((abbreviation) => ({
        value: abbreviation,
        label: abbreviation
      }))
  }, [hazardCategories])

  const hazardStatementOptions = React.useMemo(() => {
    if (!hazardStatements) return []
    return Object.keys(hazardStatements).map((code) => ({
      value: code,
      label: code
    }))
  }, [hazardStatements])

  const precautionaryStatementOptions = React.useMemo(() => {
    if (!precautionaryStatements) return []
    return Object.keys(precautionaryStatements).map((code) => ({
      value: code,
      label: code
    }))
  }, [precautionaryStatements])

  const notTrustWorthyOptions = React.useMemo(() => {
    return getRemartListOptionIds(reasonNotTrustworthyPhrases)
  },[reasonNotTrustworthyPhrases])

  let remarkListOptions = [
    ...notTrustWorthyOptions,
    { id: 'sdb_atp_incompliance_value', name: intl.formatMessage({ id: 'riskassessment.sdb_atp_incompliance' }) },
    { id: 'max_risk_value', name: intl.formatMessage({ id: 'riskassessment.risk_level-filter' }) }
  ]

  const allergenFilter = hazardStatementFilter && hazardStatementFilter.find((filter) => filter.id === 4)
  if (!allergenFilter) {
    console.error('Could not find allergenFilter')
  }
  const listingsListOptions = [
    {
      id: 'allergens',
      name: intl.formatMessage({ id: 'chemicals.allergens' }),
      filterList: allergenFilter && allergenFilter.hazard_statements.map((filter) => filter.code)
    },
    { id: 'authorization', name: intl.formatMessage({ id: 'chemicals.listing-authorization' }) },
    { id: 'restriction', name: intl.formatMessage({ id: 'chemicals.listing-restriction' }) },
    { id: 'waterdirective', name: intl.formatMessage({ id: 'chemicals.listing-waterdirective' }) },
    { id: 'sin', name: intl.formatMessage({ id: 'chemicals.listing-sin' }) },
    { id: 'cmr', name: intl.formatMessage({ id: 'chemicals.listing-cmr' }) }
  ]

  const getFilterForType = (type) => {
    switch (type) {
      case 'prio':
        return _.filter(hazardCategoryFilter, ['filter_type', type]).map((hcf) => {
          return {
            label: hcf['label_' + user.user.language],
            value: hcf?.id
          }
        })
      default:
        return _.filter(hazardStatementFilter, ['filter_type', type]).map((hf) => {
          return {
            label: hf['label_' + user.user.language],
            value: hf.hazard_statements.map((hs) => {
              return hs.code
            })
          }
        })
    }
  }

  const locationOptions = useMemo(
    () => !locationsLoading && filter.locations && transformLocations(locations),
    [locations, locationsLoading, filter.locations]
  )

  const handleLocationFilter = (value) => {
    let locationNames = []
    const getLocationName = (o, k) => {
      o.forEach((l) => {
        if (k + '' === l.key + '') {
          locationNames.push(l.label)
        }
        if (l.children && l.children.length > 0) {
          return getLocationName(l.children, k)
        }
      })
    }

    value.forEach((k) => {
      getLocationName(locationOptions, k)
    })
    filter.setLocations({ ...filter.locations, selectedKeys: [...value], locationNames: locationNames })
  }

  let tabs = [
    <ExpanderTab
      key={intl.formatMessage({ id: 'chemicals.clp_class.health' })}
      headerStyle={{ borderLeft: `6px solid ${filterColors.he}` }}
      header={intl.formatMessage({ id: 'chemicals.clp_class.health' })}
      disabled={hazardStatementFilterLoading}
    >
      <CheckboxGroup
        options={getFilterForType('health')}
        onChange={(value) => {
          filter.setHe(value)
          setFirst(0)
        }}
        value={filter.he}
      />
    </ExpanderTab>,
    <ExpanderTab
      key={intl.formatMessage({ id: 'chemicals.clp_class.physical' })}
      headerStyle={{ borderLeft: `6px solid ${filterColors.pd}` }}
      header={intl.formatMessage({ id: 'chemicals.clp_class.physical' })}
      disabled={hazardStatementFilterLoading}
    >
      <CheckboxGroup
        options={getFilterForType('physical')}
        onChange={(value) => {
          filter.setPd(value)
          setFirst(0)
        }}
        value={filter.pd}
      />
    </ExpanderTab>,
    user.user.active_market !== 'DK' ? (
      <ExpanderTab
        headerStyle={{ borderLeft: `6px solid ${filterColors.prio}` }}
        key={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_prio' })}
        header={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_prio' })}
        disabled={hazardCategoryFilterLoading}
      >
        <CheckboxGroup
          options={getFilterForType('prio')}
          onChange={(value) => {
            filter.setPrio(value)
            setFirst(0)
          }}
          value={filter.prio}
        />{' '}
      </ExpanderTab>
    ) : (
      []
    ),
    <ExpanderTab
      headerStyle={{ borderLeft: `6px solid ${filterColors.candidate}` }}
      key={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' })}
      header={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' })}
    >
      <Checkbox
        onChange={(e) => {
          filter.setCandidate(e.target.checked)
          setFirst(0)
        }}
        label={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' })}
        value={filter.candidate}
      />
    </ExpanderTab>,
    <ExpanderTab
      headerStyle={{ borderLeft: `6px solid ${filterColors.listingsList}`, display: filter.listingsList ? '' : 'none' }}
      key={intl.formatMessage({ id: 'chemicals.other-listings' })}
      header={intl.formatMessage({ id: 'chemicals.other-listings' })}
      disabled={!filter.listingsList}
    >
      {filter.listingsList && (
        <CheckboxGroup
          options={
            listingsListOptions
              ? listingsListOptions.map((remark) => ({
                  label: remark.name,
                  value: remark.id,
                  filterList: remark.filterList
                }))
              : []
          }
          onChange={(value) => {
            filter.setListingsList(value)
            setFirst(0)
          }}
          value={filter.listingsList}
        />
      )}
    </ExpanderTab>,
    <ExpanderTab
      headerStyle={{ borderLeft: `6px solid ${filterColors.tagList}`, display: filter.tagList ? '' : 'none' }}
      key={intl.formatMessage({ id: 'chemicals.tags' })}
      header={intl.formatMessage({ id: 'chemicals.tags' })}
      disabled={isTagListLoading || !filter.tagList}
    >
      {filter.tagList && (
        <CheckboxGroup
          options={tagList ? tagList.map((tag) => ({ label: tag.name, value: tag.id })) : []}
          onChange={(value) => {
            filter.setTagList(value)
            setFirst(0)
          }}
          value={filter.tagList}
        />
      )}
    </ExpanderTab>,
    <ExpanderTab
      headerStyle={{ borderLeft: `6px solid ${filterColors.remarkList}`, display: filter.remarkList ? '' : 'none' }}
      key={intl.formatMessage({ id: 'chemicals.remarks' })}
      header={intl.formatMessage({ id: 'chemicals.remarks' })}
      disabled={!filter.remarkList}
    >
      {filter.remarkList && (
        <CheckboxGroup
          options={
            remarkListOptions
              ? remarkListOptions.map((remark) => ({
                  label: remark.name,
                  value: remark.id
                }))
              : []
          }
          onChange={(value) => {
            filter.setRemarkList(value)
            setFirst(0)
          }}
          value={filter.remarkList}
        />
      )}
    </ExpanderTab>
  ]

  if (user.actingAsSuperAdmin) {
    tabs.push(
      <ExpanderTab
        headerStyle={{ borderLeft: `6px solid ${filterColors.admin}` }}
        key={'Admin'}
        header={'Admin'}
        disabled={!user.actingAsSuperAdmin}
      >
        <label id="filter_hazard_categories">
          <FormattedMessage id="chemicals.form.classification_marking_short" />
        </label>
        <MultiSelect
          id="filter_hazard_categories"
          isLoading={isHazardCategoriesLoading}
          options={hazardCategoryOptions}
          onChange={(e) => {
            filter.setHazardCategories(e.target.value)
            setFirst(0)
          }}
          value={filter.hazardCategories}
        />
        <label id="filter_hazard_statements">
          <FormattedMessage id="chemicals.form.hazard_statements" />
        </label>
        <MultiSelect
          id="filter_hazard_statements"
          isLoading={isHazardStatementsLoading}
          options={hazardStatementOptions}
          onChange={(e) => {
            filter.setHazardStatements(e.target.value)
            setFirst(0)
          }}
          value={filter.hazardStatements}
        />
        <label id="filter_precautionary_statements">
          <FormattedMessage id="chemicals.form.precautionary_statements" />
        </label>
        <MultiSelect
          id="filter_precautionary_statements"
          isLoading={isPrecautionaryStatementsLoading}
          options={precautionaryStatementOptions}
          onChange={(e) => {
            filter.setPrecautionaryStatements(e.target.value)
            setFirst(0)
          }}
          value={filter.precautionaryStatements}
        />
        <label id="filter_supplemental_hazard_information_input">
          <FormattedMessage id="chemicals.form.supplemental_hazard_information" />
        </label>
        <TextInput
          id="filter_supplemental_hazard_information_input"
          onChange={(e) => {
            filter.setSupplementalHazardInformation(e.target.value)
            setFirst(0)
          }}
          value={filter.supplementalHazardInformation}
        />
      </ExpanderTab>
    )
  }

  return (
    <>
      <div className="filter">
        <h2>
          <i className="pi pi-filter" style={{ verticalAlign: 'bottom' }} />
          <FormattedMessage id="common.filter" />
        </h2>
        <Expander
          activeIndex={[
            filter.he.length > 0 ? 0 : null,
            filter.pd.length > 0 ? 1 : null,
            filter.prio.length > 0 ? 2 : null,
            filter.candidate ? 3 : null
          ]}
        >
          {tabs}
        </Expander>
        {filter.locations && (
          <>
            <HeaderThree>
              <span style={{ backgroundColor: filterColors.location }}></span>
              {intl.formatMessage({ id: 'common.locations' })}
            </HeaderThree>

            <Tree
              options={transformLocations(locationsLoading ? [] : locations)}
              onChange={(e) => {
                handleLocationFilter(e.target.value)
                setFirst(0)
              }}
              value={filter.locations.selectedKeys}
              multiselect={true}
              expandedKeys={filter.locations.expandedKeys}
            />
          </>
        )}
      </div>
    </>
  )
}

export default injectIntl(ChemicalFilter)
